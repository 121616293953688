import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Typography, TextField, Button } from '@mui/material';
import Cookies from 'js-cookie';

import colors from '../../assets/colors';
import LogoNatoora from '../../assets/icons/IconNatoora';
import Container from '../../components/Container';
import LoadingWrapper from '../../components/LoadingWrapper';
import { PATHS } from '../../config/paths';
import i18n from '../../libs/i18n';
import { useSetPassword, useResetPassword } from '../../services/auth';
import { useAuth } from '../../stores/useAuth';
import { getFirstErrorMessage } from '../../utils/errorResponseHandler';

const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export default function NewPassword() {
  const { user_id, token } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [isPasswordWeak, setIsPasswordWeak] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { setAuth } = useAuth();
  const shouldSetPassword = location.pathname.startsWith('/set-password');

  const {
    mutate: setNewPassword,
    isLoading: isLoadingNewPassword,
    error: errorNewPassword,
  } = useSetPassword({
    onSuccess: (response) => {
      i18n.changeLanguage(response.user?.language);
      setAuth(response);
      navigate(PATHS.HOME);
    },
  });

  const {
    mutate: resetPassword,
    isLoading: isLoadingPassword,
    error: errorResetPassword,
  } = useResetPassword({
    onSuccess: (response) => {
      i18n.changeLanguage(response.user?.language);
      setAuth(response);
      navigate(PATHS.HOME);
    },
  });

  useEffect(() => {
    const cookiesName = ['natoora', 'csrftoken', 'sessionid'];
    localStorage.removeItem('authStore');
    const replaceCookie = () => {
      cookiesName.forEach((cookie) => {
        Cookies.set(cookie, cookie, { path: '/' });
        Cookies.remove(cookie, { path: '/' });
      });
    };

    replaceCookie();
  }, []);

  const backendErrorMessage = getFirstErrorMessage(
    errorNewPassword || errorResetPassword,
  );

  const checkDifferentPasswords = () => {
    return (
      password && passwordConfirmation && passwordConfirmation !== password
    );
  };

  const changePassword = () => {
    if (
      !passwordRegex.test(passwordConfirmation) &&
      passwordConfirmation !== ''
    ) {
      setIsPasswordWeak(true);
      return;
    }
    setIsPasswordWeak(false);

    if (passwordConfirmation === password && password !== '') {
      if (shouldSetPassword) {
        setNewPassword({ userId: user_id, token, password });
      } else {
        resetPassword({ userId: user_id, token, password });
      }
    }
  };

  return (
    <LoadingWrapper loading={isLoadingPassword || isLoadingNewPassword}>
      <Container paddingTop={10}>
        <Box
          width="448px"
          height="434px"
          border={`1px solid ${colors.disabledGrey}`}
          borderRadius="8px"
          p="45px 25px"
          bgcolor={colors.background}
        >
          <LogoNatoora height="45px" />

          <Typography variant="title">
            {shouldSetPassword ? 'Create password' : 'Update Password'}
          </Typography>
          <Typography variant="body1" py={1}>
            Your password should be a minimum of 8 characters, with at least 1
            number and 1 special character.
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            height="265px"
            padding="20px 0"
          >
            <Box display="flex" flexDirection="column" gap="16px" width="100%">
              <TextField
                id="newpassword"
                label="New password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setIsPasswordWeak(false);
                }}
              />
              <TextField
                id="repeatpassword"
                label="Repeat password"
                type="password"
                fullWidth
                value={passwordConfirmation}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                  setIsPasswordWeak(false);
                }}
                error={checkDifferentPasswords() || false}
                helperText={
                  checkDifferentPasswords()
                    ? 'Please make sure your passwords match'
                    : ''
                }
              />
            </Box>

            {backendErrorMessage ? (
              <Typography alignSelf="baseline" variant="caption" color="error">
                {backendErrorMessage}
              </Typography>
            ) : null}

            {isPasswordWeak ? (
              <Box
                display="flex"
                width="100%"
                height="52px"
                justifyContent="flex-start"
                alignItems="center"
                borderRadius="4px"
                bgcolor={colors.backgroundError}
              >
                <Box display="flex" gap={2} marginLeft={3}>
                  <ErrorOutlineOutlinedIcon color="error" />
                  <Typography
                    variant="body1"
                    component="div"
                    color={colors.persianPlum}
                  >
                    The password is weak.
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Button variant="gradient" onClick={changePassword}>
              CONFIRM
            </Button>
          </Box>
        </Box>
      </Container>
    </LoadingWrapper>
  );
}
